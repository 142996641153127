import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'

export function getMetaFromPage(page: Page) {
    if (page.meta_title != null && page.meta_title != '') {
        return page.meta_title
    }

    if (page.title != null && page.title != '') {
        return page.title
    }

    return 'Bidr'
}

export function getMetaDescription(page: Page) {
    if (page.meta_description != '') {
        return page.meta_description
    }

    return ''
}

export function getMetaOGTitle(page: Page) {
    if (page.social_title != null && page.social_title != '') {
        return page.social_title
    }

    if (page.meta_title != null && page.meta_title != '') {
        return page.meta_title
    }

    if (page.title != null && page.title != '') {
        return page.title
    }

    return 'Bidr'
}

export function getOGDescription(page: Page) {
    if (page.social_description != null && page.social_description != '') {
        return page.social_description
    }

    if (page.meta_description != '') {
        return page.meta_description
    }

    return ''
}

export function getOGImage(page: Page) {
    if (page.social_image?.filename_disk) {
        return getDirectusAssetLink(page?.social_image?.filename_disk)
    }

    return ''
}
